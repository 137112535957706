<template>
  <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#task-modal" @click="deleteTask">Delete Task</button>
</template>

<script>
export default {
  props: ['task'],

  inject: ['GStore'],

  methods: {
    deleteTask () {
      console.log('Task deleted')

      this.GStore.flashMessage = this.task.title + ' was deleted'
      setTimeout(() => {
        this.GStore.flashMessage = ''
      }, 3000)

      this.$router.push({
        name: 'TaskDetails'
      })
    }
  }
}
</script>
