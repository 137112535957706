<template>
  <ul class="list-group list-group-flush">
    <router-link class="task-link" :to="{ name: 'TaskDetails', params:{id: task.id}}">
      <li class="list-group-item"><b>{{ task.title }}</b> on the {{ task.date }} at {{ task.time }}</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'TaskCard',

  props: {
    task: Object
  }
}
</script>

<style scoped>
.task-link {
  color: black;
  text-decoration: none;
}

.task-link:hover {
  box-shadow: 0 0 20px 3px grey;
  margin: 5px;
}
</style>
