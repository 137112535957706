<template>
  <transition name="fade">
    <div id="flashMessage" v-if="GStore.flashMessage">
      {{ GStore.flashMessage }}
      <button @click="GStore.flashMessage = ''" class="btn btn-secondary">Close</button>
    </div>
  </transition>

  <!--<div class="modal" id="task-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ GStore.flashMessage }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>-->

  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/>
</template>

<script>
export default {
  inject: ['GStore']
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#flashMessage {
  animation-name: bluefade;
  animation-duration: 3s;
}

@keyframes bluefade {
  from {
    background: lightblue;
  }
  to {
    background: transparent;
  }
}

 .fade-enter-from,
 .fade-leave-to {
   opacity: 0;
 }

 .fade-enter-active,
 .fade-leave-active {
   transition:  opacity 1s ease-out;
 }

.modal-content {
  border: 1px solid #192E5B;
}
</style>
