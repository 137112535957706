<template>
  <p>On {{ task.date }} at {{ task.time }}. It will take approximately {{ task.duration }}</p>
  <p>{{ task.description }}</p>
</template>

<script>
export default {
  props: ['task']
}
</script>

<style scoped>
.task-details {
    border: 1px solid black;
    width: 40%;
    margin: 0 auto 0 auto;
    padding: 10px;
}
</style>
