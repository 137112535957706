<template>
    <div v-if="task" class="task-details">
        <h1>{{ task.title }}</h1>
        <div id="nav">
          <router-link :to="{ name: 'TaskDetails', params: { id } }"> Details</router-link>
          <router-link :to="{ name: 'TaskEdit', params: { id } }"> Edit</router-link>
          <router-link :to="{ name: 'TaskDelete', params: { id } }"> Delete</router-link>
        </div>
        <router-view :task="task" />
    </div>
</template>

<script>
import TaskService from '@/services/TaskService.js'

export default {
  props: ['id'],

  data () {
    return {
      task: null
    }
  },

  created () {
    TaskService.getTask(this.id)
      .then(response => {
        console.log('task: ', response.data)
        this.task = response.data
      })
      .catch(error => {
        console.log('ERROR' + error)
      })
  }
}
</script>

<style scoped>
.task-details {
    border: 1px solid black;
    width: 40%;
    margin: 0 auto 0 auto;
    padding: 10px;
}
</style>
