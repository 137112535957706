<template>
  <div class="card" style="width: 24rem;">
    <div class="card-header">
      Tasks
    </div>
    <TaskCard v-for="task in tasks" :key="task.id" :task="task"/>

    <div class="pagination">
      <router-link id="prev"
        :to="{ name: 'TaskList', query: { page: page - 1 } }"
        v-if="page != 1">
      &#60; Prev
      </router-link>

      <router-link id="next"
        :to="{ name: 'TaskList', query: { page: page + 1 } }"
        v-if="hasNextPage">
      Next &#62;
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TaskCard from '../components/TaskCard.vue'
import TaskService from '@/services/TaskService.js'
import { watchEffect } from 'vue'

export default {
  name: 'TaskList',

  props: ['page'],

  components: {
    TaskCard
  },

  data () {
    return {
      tasks: null,
      totalTasks: 0
    }
  },

  computed: {
    hasNextPage () {
      var totalPages = Math.ceil(this.totalTasks / 2)
      return this.page < totalPages
    }
  },

  created () {
    watchEffect(() => {
      console.log('Page is ' + this.page)
      TaskService.getTasks(2, this.page)
        .then(response => {
          this.tasks = response.data
          this.totalTasks = response.headers['x-total-count']
        })
        .catch(error => {
          console.log('ERRORS' + error)
        })
    })
  }
}
</script>

<style scoped>
.card {
  margin: 15px auto 0 auto;
}

.pagination {
  display: flex;
  width: 382px;
}

.pagination a {
  flex: 1;
  text-decoration: none;
  color: black;
}

#prev {
  text-align: left;
}

#next {
  text-align: right;
}
</style>
